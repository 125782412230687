/* eslint-disable no-unused-vars */
// Modules
import React, { createContext, useReducer, useContext, useEffect } from 'react'
import * as qs from 'query-string'
// API
import api from '../helpers/api'
// Components
import { Loader } from '../components'
// Helpers
import { decryptSession, encryptSession } from '../helpers/session'
import { config } from '../config'

export const SESSION = 'session'
export const SET_USER = 'SET_USER'
export const LOGOUT = 'LOGOUT'

const AuthContext = createContext()

export const authLogin = async ({ token, successCb, errorCb }) => {
  return await api
    .post(`/auth/login?authToken=${token}`)
    .then((response) => {
      successCb & successCb(response.data.data)
    })
    .catch((e) => {
      errorCb && errorCb(e)
      console.log('Error calling authLogin: ', e)
    })
}

export const AuthProvider = ({ children }) => {
  const initialState = {
    isAuthenticated: false,
    auth: null,
    loading: true
  }

  const authReducer = (state, action) => {
    switch (action.type) {
      case SET_USER:
        return {
          ...state,
          isAuthenticated: true,
          auth: action.payload,
          loading: false
        }
      case LOGOUT:
        return initialState
      default:
        return state
    }
  }

  const [authState, dispatch] = useReducer(authReducer, initialState)

  const fetchUserData = async (dispatch, location) => {
    const { token } = qs.parse(location.search)
    const encryptedSession = localStorage.getItem(SESSION)

    try {
      if (token) {
        await authLogin({
          token,
          successCb: (user) => {
            localStorage.setItem(SESSION, encryptSession(user))
            dispatch({ type: SET_USER, payload: user })
          },
          errorCb: () => {
            localStorage.removeItem(SESSION)
            window.location.href = config.loginRedirectUrl
          }
        })
      } else if (!token && encryptedSession) {
        dispatch({ type: SET_USER, payload: decryptSession(SESSION, true) })
      }
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }

  useEffect(() => {
    fetchUserData(dispatch, location)
  }, [])

  return (
    <AuthContext.Provider value={{ authState, dispatch }}>
      {authState.loading ? <Loader /> : children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
