// Modules
import Ripples from 'react-ripples'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const ActionButton = ({ icon, text, triggerFunction }) => {
  return (
    <Ripples className="react-16 color-off-white">
      <Button
        className={text ? 'btn-primary' : 'btn-icon'}
        color="primary"
        onClick={triggerFunction}>
        <img src={icon} className="img-fluid" alt="action-buttons" />
        {text && <span>{text}</span>}
      </Button>
    </Ripples>
  )
}

ActionButton.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  triggerFunction: PropTypes.func
}

export default ActionButton
