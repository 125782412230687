import colors from '../../helpers/colors'

const styles = {
  checked: {
    backgroundColor: colors.digitalBlue,
    border: `1px solid ${colors.digitalBlue}`
  },
  checkbox: {
    alignItems: 'center',
    backgroundColor: colors.paleGrey,
    border: `1px solid ${colors.blueyGrey}`,
    borderRadius: '4px',
    color: colors.white,
    cursor: 'pointer',
    display: 'flex',
    fontSize: '16px',
    height: '16px',
    justifyContent: 'center',
    minWidth: '16px',
    width: '16px'
  },
  container: {
    alignItems: 'center',
    display: 'flex'
  },
  error: {
    border: `1px solid ${colors.melon}`
  },
  input: {
    cursor: 'pointer',
    height: '16px',
    margin: '0px',
    minWidth: '16px',
    opacity: '0',
    position: 'absolute',
    width: '16px'
  }
}

export default styles
