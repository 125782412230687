import colors from '../../../../../helpers/colors'

const styles = {
  container: {
    backgroundColor: colors.white,
    borderRadius: '12px 12px 0px 0px',
    margin: '0px 24px',
    padding: '24px 24px 16px',
    position: 'fixed',
    bottom: '0',
    width: '80%'
  },
  block: {
    display: 'grid',
    gap: '16px',
    width: '100%'
  },
  innerContainer: {
    display: 'grid',
    gap: '24px',
    gridAutoFlow: 'column',
    alignItems: 'center'
  },
  title: {
    color: colors.darkSlateBlue,
    margin: '0',
    fontSize: '16px'
  },
  hide: {
    display: 'none'
  },
  warningPopupImage: {
    background: colors.paleYellow,
    color: colors.toastedYellow
  },
  button: {
    border: ' 1px solid rgb(35, 54, 108)',
    borderRadius: '10px',
    fontSize: '9px',
    fontWeight: 'bold',
    lineHeight: '10px',
    textTransform: 'uppercase'
  }
}

export default styles
