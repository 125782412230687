// Modules
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Button } from 'reactstrap'
import Ripples from 'react-ripples'
import PropTypes from 'prop-types'
// Helpers
import { hasPermission } from '../../../../../helpers/permissions'
import { convertDateFormat } from '../../../../../helpers/dates'
// Util
import { routerPath, PATH, ACTION } from '../../../../../constants/constants'
// Components
import { ShareButton } from '../../../../../components'
// Assets
import { BiShareAlt, BiMailSend } from 'react-icons/bi'

const TableListing = ({ t, auth, recipients, location = {}, handleSendEmailToRecipient }) => {
  const [showShareModal, setShowShareModal] = useState(false)
  const [recipientData, setRecipientData] = useState({})
  const { id, issuerId, issuerName } = location

  const handleToggleShareStatus = () => {
    setShowShareModal((prevState) => !prevState)
  }

  return (
    <Table responsive hover borderless>
      <thead>
        <tr>
          <th>{t('recipient-list-page.table-data.thname')}</th>
          <th>{t('recipient-list-page.table-data.themail')}</th>
          <th>{t('recipient-list-page.table-data.creation-date')}</th>
          <th>
            {t('recipient-list-page.table-data.thawarddate1')}
            <br />
            {t('recipient-list-page.table-data.thawarddate2')}
          </th>
          <th>{t('recipient-list-page.table-data.thexpidate')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {recipients.length !== 0 &&
          recipients.map((recipient, i) => (
            <tr key={i}>
              <td>
                <Link
                  to={{
                    pathname: routerPath.RECIPIENT_DETAILS,
                    state: {
                      id: recipient.id,
                      badgeId: id,
                      issuerId,
                      issuerName
                    }
                  }}>
                  {`${recipient.professionTitle ? `${recipient.professionTitle}` : ''} ${
                    recipient.firstName
                  } ${recipient.lastName}`}
                </Link>
              </td>
              <td>
                <span>{recipient.email}</span>
              </td>
              <td>
                <span>{convertDateFormat(recipient.createdAt, false, false)}</span>
              </td>
              <td>
                <span>{convertDateFormat(recipient.issueDate, false, true)}</span>
              </td>
              <td>
                <span>
                  {recipient.expiryDate !== null && recipient.expiryDate !== 0
                    ? convertDateFormat(recipient.expiryDate, false, true)
                    : t('recipient-list-page.table-data.no-expiry')}
                </span>
              </td>
              <td className="center-items-with-space">
                <>
                  {hasPermission(auth?.role, PATH.AWARDS, ACTION.SEND_MAIL) && (
                    <Ripples className="react-icon-48 color-off-white">
                      <Button
                        onClick={() => {
                          const awardID = recipient.id
                          handleSendEmailToRecipient(awardID)
                        }}
                        color="primary"
                        className="btn-icon btn-40 float-left">
                        <BiMailSend size="18" />
                      </Button>
                    </Ripples>
                  )}
                  {hasPermission(auth?.role, PATH.AWARDS, ACTION.SHARE) && (
                    <Ripples className="react-icon-48 color-off-white">
                      <Button
                        onClick={() => {
                          setShowShareModal(true)
                          setRecipientData({ meta: recipient.meta, email: recipient.email })
                        }}
                        color="primary"
                        className="btn-icon btn-40 float-right">
                        <BiShareAlt size="18" />
                      </Button>
                    </Ripples>
                  )}
                </>
              </td>
            </tr>
          ))}
      </tbody>
      <ShareButton
        show={showShareModal}
        changeSetShow={handleToggleShareStatus}
        setShowToFalse={() => {
          setShowShareModal(false)
        }}
        recipient={recipientData}
      />
    </Table>
  )
}

TableListing.propTypes = {
  recipients: PropTypes.array.isRequired,
  location: PropTypes.object,
  handleSendEmailToRecipient: PropTypes.func.isRequired
}

export default TableListing
