// Modules
import { Col, Container, Row, Button } from 'reactstrap'
import Ripples from 'react-ripples'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const RouteNotFound = () => {
  const { t } = useTranslation()
  const history = useHistory()
  function handleClick() {
    history.push('/')
  }

  return (
    <>
      <section className="pagenotfound-section section mid-section">
        <Container fluid className="custom-width">
          <div className="notfound-data text-center">
            <Row className="justify-content-center">
              <Col xl={10} lg={10} md={12}>
                <h1 className="fw700">{t('notfound.digit')}</h1>
                <h2 className="page-title text-secondary-dark">{t('notfound.head')}</h2>
                <p className="page-sub-title text-secondary-dark">{t('notfound.text1')}</p>
                <div className="text-center d-flex justify-content-center">
                  <Ripples className="react-12 color-off-white">
                    <Button
                      className="btn btn-primary btn-cu-xl blue common-16 fw700"
                      onClick={handleClick}>
                      {t('notfound.btn-text')}
                    </Button>
                  </Ripples>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default RouteNotFound
