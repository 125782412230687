// Modules
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
// Helpers
import { config } from '../../../config'
import { hasAccess } from '../../../helpers/permissions'
import { SIDEBAR } from '../../../constants/constants'
// Assets
import HomeIcon from '../../../assets/image/icon-home.svg'
import SettingIcon from '../../../assets/image/icon-setting.svg'
import SuppliersIcon from '../../../assets/image/icon-suppliers.svg'
import UnionIcon from '../../../assets/image/icon-union.svg'
import SubtractIcon from '../../../assets/image/icon-subtract.svg'
import AdsIcon from '../../../assets/image/icon-ads-menu.svg'
import BadgeIcon from '../../../assets/image/icon-badges.svg'
import FilterIcon from '../../../assets/image/icon-filter.svg'

const Sidebar = ({ auth, active }) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  useEffect(() => {
    active(show)
  }, [show, active])

  const sidebarItems = [
    {
      path: config.redirectToAnother,
      endpoint: '',
      icon: HomeIcon,
      label: t('sidebar.first'),
      permission: SIDEBAR.DASHBOARD
    },
    {
      path: config.redirectToAnother,
      endpoint: config.apiEndPoints.userRedirect,
      icon: SettingIcon,
      label: t('sidebar.second'),
      permission: SIDEBAR.USER_MANAGEMENT
    },
    {
      path: config.redirectToAnother,
      endpoint: config.apiEndPoints.mappingRedirect,
      icon: SuppliersIcon,
      label: t('sidebar.fourth'),
      permission: SIDEBAR.OFFER_MANAGEMENT
    },
    {
      path: config.redirectToAnother,
      endpoint: config.apiEndPoints.offersRedirect,
      icon: UnionIcon,
      label: t('sidebar.fifth'),
      permission: SIDEBAR.OFFER_OVERVIEW
    },
    {
      path: config.redirectToAnother,
      endpoint: config.apiEndPoints.invoicingRedirect,
      icon: SubtractIcon,
      label: t('sidebar.sixth'),
      permission: SIDEBAR.BILLING
    },
    {
      path: config.redirectToAnother,
      endpoint: config.apiEndPoints.marketingmaterialsRedirect,
      icon: AdsIcon,
      label: t('sidebar.seventh'),
      permission: SIDEBAR.MARKETING
    },
    {
      path: '/',
      endpoint: '',
      icon: BadgeIcon,
      label: t('sidebar.eightth'),
      permission: SIDEBAR.OPEN_BADGES
    }
  ]

  const changeSetShow = () => {
    setShow((prevState) => !prevState)
  }

  return (
    <>
      <div className={`sidebar-left ${show ? 'active' : ''}`}>
        <Button color="transparent" className="btn-check-sidebar" onClick={changeSetShow}>
          <img src={FilterIcon} className="img-fluid" alt="images" />
        </Button>
        <ul>
          {sidebarItems.map((item, index) => {
            if (!hasAccess(auth?.role, item.permission)) return null

            const href = `${item.path}${item.endpoint}`
            const isActive = window.location.pathname === href

            return (
              <li key={index} className={isActive ? 'active' : ''}>
                <a href={href} rel="noopener noreferrer">
                  <div className="side-data">
                    <div className="icons-data">
                      <img src={item.icon} className="img-fluid" alt={item.label} />
                    </div>
                    <div className="text-data">{item.label}</div>
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

Sidebar.propTypes = {
  active: PropTypes.func.isRequired
}

export default Sidebar
