// Modules
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
// Assets
import Iconback from '../../../../../assets/image/icon-left.svg'

const Breadcrumb = ({ translate, routerPath, location }) => {
  return (
    <div className="arrow-data">
      <div className="left-data">
        <div className="head-back-data">
          <div className="back-data">
            <Link
              to={{
                pathname: routerPath.ISSUER_DETAILS,
                state: {
                  id: location.state.issuerId,
                  name: location.state.issuerName
                }
              }}>
              <img src={Iconback} className="img-fluid" alt="icon-back" />
            </Link>
          </div>
          <div className="head-data">
            <h3>{translate('badges.upset.back')}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

Breadcrumb.propTypes = {
  translate: PropTypes.func,
  routerPath: PropTypes.object,
  location: PropTypes.object
}

export default Breadcrumb
