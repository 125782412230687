// Modules
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
// Store
import { store } from './redux/store'
import { AuthProvider } from './ducks/Auth.ducks'
// Components
import App from './App'
// Helpers
import i18n from './i18n/i18n'
import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)
