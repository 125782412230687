// Modules
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Actions
import { useAuth } from '../../../ducks/Auth.ducks'
import { getRecepientAwardDetails } from '../../../ducks/Awards.ducks'
// Components
import { HeaderTitle } from '../../../components'
import RecipientDetails from './RecipientDetails'
// Helpers
import { isEmptyObject } from '../../../helpers/objects'

const RecipientDetailsMain = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { awardDetails } = useSelector((state) => state.awards)

  const { authState: { auth } = {} } = useAuth()
  const awardId = location.state.id

  useEffect(() => {
    if (!location.state) {
      history.goBack()
    }

    const asyncApiCall = async () => {
      await getRecepientAwardDetails({ id: awardId, dispatch })
    }

    asyncApiCall()
  }, [getRecepientAwardDetails])

  return (
    <>
      <Helmet>
        <title>IHK - Empfänger Details</title>
      </Helmet>
      <section className="mid-section">
        <div className="container-fluid common-container">
          <HeaderTitle title={t('recipient-details-page.head.title')} />
          {!isEmptyObject(awardDetails) && (
            <RecipientDetails t={t} auth={auth} awardDetails={awardDetails} />
          )}
        </div>
      </section>
    </>
  )
}

export default RecipientDetailsMain
