// Modules
import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
// Assets
import IconPlus from '../../../../../assets/image/icon-dark-plus.svg'
import IconClose from '../../../../../assets/image/icon-delete.svg'
import IconToastError from '../../../../../assets/image/icon-toast-error.png'
// Styles
import classes from '../../../../../assets/css/addTab.module.css'

const ImageDropbox = ({ setFieldValue }) => {
  const { t } = useTranslation()
  const [files, setFiles] = useState([])

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize: 10485760,
    accept: 'image/png',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length !== 0) {
        setFieldValue('image', acceptedFiles[0])
        setFiles(
          acceptedFiles.map((file) => {
            return Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          })
        )
      } else {
        toast.error(t('error-msg.image-error'), {
          icon: <img src={IconToastError} className="img-fluid" alt="images" />
        })
      }
    }
  })

  const deletefn = async (i) => {
    const newFiles = [...files]
    newFiles.splice(i, 1)
    setFiles(newFiles)
    setFieldValue('image', newFiles)
  }

  const renderThumbs = files.map((file, i) => (
    <div className="dropzone-img-view" key={file.name}>
      <div className="thumb">
        <Button
          color="danger"
          type="button"
          onClick={() => deletefn(file, i)}
          className="btn-delete">
          <img src={IconClose} className="img-fluid" />
        </Button>
      </div>
      <div className="dropzone-inner">
        <img className="img-fluid" src={file.preview} />
      </div>
    </div>
  ))

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [files])

  return (
    <>
      {files.length === 0 ? (
        <div
          {...getRootProps({
            className: 'dropzone'
          })}>
          <input {...getInputProps()} />
          <div className="dropzone-inner">
            <img src={IconPlus} className="img-fluid" alt="img-plus" />
            <p>
              {t('create-badge-page.tab1.image-placeholder1')}
              <br />
              {t('create-badge-page.tab1.image-placeholder2')}
            </p>
          </div>
        </div>
      ) : (
        <aside className={classes.thumbsContainer}>{renderThumbs}</aside>
      )}
    </>
  )
}

ImageDropbox.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  image: PropTypes.string
}

export default ImageDropbox
