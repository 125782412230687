// Modules
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
// Actions
import { useAuth } from '../../../ducks/Auth.ducks'
import { getBadgeAwardRecipients } from '../../../ducks/Awards.ducks'
// Components
import { HeaderTitle } from '../../../components'
import RecipientsListing from './RecipientsListing'

const RecipientsListingMain = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { awardRecipients, isDataEmpty } = useSelector((state) => state.awards)

  const { authState: { auth } = {} } = useAuth()
  const badgeId = location.state.id

  useEffect(() => {
    if (!location.state) {
      history.goBack()
    }

    const asyncApiCall = async () => {
      await getBadgeAwardRecipients({ id: badgeId, dispatch })
    }

    asyncApiCall()
  }, [getBadgeAwardRecipients])

  return (
    <>
      <Helmet>
        <title>IHK - Empfänger Auflistung</title>
      </Helmet>
      <section id="recipientScrollData" className="mid-section">
        <div className="container-fluid common-container">
          <HeaderTitle title={t('awards.listing.page-title')} />
          {awardRecipients && (
            <RecipientsListing
              t={t}
              auth={auth}
              recipients={awardRecipients}
              isDataEmpty={isDataEmpty}
            />
          )}
        </div>
      </section>
    </>
  )
}

export default RecipientsListingMain
