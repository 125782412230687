// Api
import api from '../helpers/api'
import { createFormData } from '../helpers/formData'

export const SAVE_BADGES_DATA = 'SAVE_BADGES_DATA'
export const SAVE_BADGE_BY_ID_DATA = 'SAVE_BADGE_BY_ID_DATA'
export const SET_EMPTY_DATA_FLAG_BADGES = 'SET_EMPTY_DATA_FLAG_BADGES'
export const SET_LOADING = 'SET_LOADING'

const defaultState = {
  badges: [],
  badge: undefined,
  isDataEmpty: false,
  loading: true
}

// Reducer
export default (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_BADGES_DATA: {
      return Object.assign({}, state, {
        ...state,
        badges: action.payload
      })
    }
    case SAVE_BADGE_BY_ID_DATA: {
      return Object.assign({}, state, {
        ...state,
        badge: action.payload
      })
    }
    case SET_EMPTY_DATA_FLAG_BADGES: {
      return Object.assign({}, state, {
        ...state,
        isDataEmpty: action.payload
      })
    }
    case SET_LOADING: {
      return Object.assign({}, state, {
        ...state,
        loading: action.payload
      })
    }
    default:
      return state
  }
}

export const saveBadges = (badges) => {
  return {
    type: SAVE_BADGES_DATA,
    payload: badges
  }
}

export const saveBadgeById = (badge) => {
  return {
    type: SAVE_BADGE_BY_ID_DATA,
    payload: badge
  }
}

export const saveDataEmpty = (isDataEmpty) => {
  return {
    type: SET_EMPTY_DATA_FLAG_BADGES,
    payload: isDataEmpty
  }
}

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading
  }
}

export const getBadges = async ({ id, dispatch }) => {
  return await api
    .get(`badge/get?issuerId=${id}`)
    .then((response) => {
      const { data, status } = response.data
      dispatch(saveBadges(data))
      if (status === 200) {
        dispatch(saveDataEmpty(true))
        dispatch(setLoading(false))
      }
    })
    .catch((e) => {
      console.log('Error calling getBadges: ', e)
    })
}

export const getBadgeById = async ({ id, dispatch }) => {
  return await api
    .get(`badge/get/${id}`)
    .then((response) => {
      dispatch(saveBadgeById(response.data.data))
    })
    .catch((e) => {
      console.log('Error calling getBadgeById: ', e)
    })
}

export const deleteBadge = async ({ id, errorCb, successCb }) => {
  return await api
    .post(`badge/delete/${id}`)
    .then(() => {
      successCb && successCb()
    })
    .catch((error) => {
      console.log('Error calling deleteBadge: ', error)
      errorCb && errorCb(error.response.status)
    })
}

export const uploadBadgeImage = async ({ data, errorCb, successCb }) => {
  const dataObj = createFormData(data)

  return await api
    .postFile({ path: 'badge/upload-image', data: dataObj })
    .then((response) => {
      successCb && successCb(response.data.data)
    })
    .catch((error) => {
      console.log('Error calling uploadBadgeImage: ', error)
      errorCb && errorCb(error.response.status)
    })
}

export const createBadge = async ({ data, errorCb, successCb }) => {
  return await api
    .post('badge/create', data)
    .then(() => {
      successCb && successCb()
    })
    .catch((e) => {
      console.log('Error calling createBadge: ', e)
      errorCb && errorCb()
    })
}

export const updateBadge = async ({ id, data, errorCb, successCb }) => {
  return await api
    .post(`badge/update/${id}`, data)
    .then(() => {
      successCb && successCb()
    })
    .catch((e) => {
      console.log('Error calling updateBadge: ', e)
      errorCb && errorCb()
    })
}

export const transferBulkBadges = async ({ data, successCb, errorCb }) => {
  return await api
    .post('badge/transfer-badges', data)
    .then(() => {
      successCb && successCb()
    })
    .catch((e) => {
      console.log('Error calling transferBulkBadges: ', e)
      errorCb && errorCb()
    })
}
