/**
 * Converts a date into "dd.mm.yyyy" format. It can adjust the date to the next day and
 * also handle Unix timestamp input.
 *
 * @param {string | number} date - The date to be formatted. Can be a date string or Unix timestamp.
 * @param {boolean} flagForNextDay - If true, shifts the date to the next day.
 * @param {boolean} timeInSecondFlag - If true, treats the input date as a Unix timestamp in seconds.
 * @returns {string} The formatted date as a string in "dd.mm.yyyy" format.
 */

export const convertDateFormat = (date, flagForNextDay = false, timeInSecondFlag = false) => {
  // eslint-disable-next-line prefer-const
  let d = timeInSecondFlag ? new Date(date * 1000) : new Date(date)

  if (flagForNextDay) {
    d.setDate(d.getDate() + 1)
  }

  const day = String(d.getDate()).padStart(2, '0')
  const month = String(d.getMonth() + 1).padStart(2, '0')
  const year = d.getFullYear()

  return `${day}.${month}.${year}`
}
