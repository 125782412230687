// Modules
import React from 'react'
import { Form, Field } from 'formik'
import { Row, Col, Button } from 'reactstrap'
import Select from 'react-select'
import Ripples from 'react-ripples'
import PropTypes from 'prop-types'

const IssuerForm = ({
  t,
  issuer,
  editMode,
  chambersList,
  errors,
  touched,
  values,
  setFieldValue,
  handleBackToAction
}) => {
  return (
    <Form>
      <Row>
        <Col lg={8}>
          <div className="tab-field-data">
            <div className="text-field-data tab2">
              <>
                {editMode ? (
                  <div>
                    <p className="fw700 mb-0">{t('issuers.form.title-edit')}</p>
                    <p>{t('issuers.form.subtitle-edit')}</p>
                  </div>
                ) : (
                  <div>
                    <p className="fw700 mb-0">{t('issuers.form.title-create')}</p>
                    <p>{t('issuers.form.subtitle-create')}</p>
                  </div>
                )}
              </>
            </div>
            <div
              className={`form-group floating-label-group ${
                errors.chamber && touched.chamber && 'error-gives'
              }`}>
              <Select
                value={values.chamber}
                options={chambersList}
                isClearable={false}
                isSearchable={true}
                id="name"
                name="name"
                className="react-select with-search"
                classNamePrefix="select"
                placeholder={t('issuer-create.form.name-placeholder')}
                onChange={(value) => setFieldValue('chamber', value)}
              />
              {errors.chamber && touched.chamber ? (
                <div className="error-msg">{errors.chamber}</div>
              ) : null}
            </div>
            <div
              className={`form-group floating-label-group ${
                errors.url && touched.url && 'error-gives'
              }`}>
              <Field
                type="text"
                className="form-control"
                name="url"
                id="url"
                autoComplete="off"
                placeholder={t('issuer-create.form.website-url-placeholder')}
              />
              <label className="floating-label">
                {t('issuer-create.form.website-url-floting-lable')}
              </label>
              {errors.url && touched.url ? <div className="error-msg">{errors.url}</div> : null}
            </div>
            <div
              className={`form-group floating-label-group ${
                errors.email && touched.email && 'error-gives'
              }`}>
              <Field
                type="email"
                className="form-control"
                name="email"
                id="email"
                autoComplete="off"
                placeholder={t('issuer-create.form.email-placeholder')}
              />
              <label className="floating-label">{t('issuer-create.form.email-placeholder')}</label>
              {errors.email && touched.email ? (
                <div className="error-msg">{errors.email}</div>
              ) : null}
            </div>
            <div
              className={`form-group floating-label-group mb23 ${
                errors.description && touched.description && 'error-gives'
              }`}>
              <Field
                as="textarea"
                name="description"
                id="description"
                className="form-control"
                rows="5"
                autoComplete="off"
                placeholder={t('issuer-create.form.description-placeholder')}
              />
              <label className="floating-label">
                {t('issuer-create.form.description-placeholder')}
              </label>
              {errors.description && touched.description ? (
                <div className="error-msg">{errors.description}</div>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
      <div className="button-tab-bottom">
        <Ripples className="react-12 color-light-blue">
          <Button
            color="white"
            className="btn-cu-lg"
            onClick={() => handleBackToAction({ id: issuer?.id, value: issuer?.name })}>
            {t('issuers.form.buttons.cancel')}
          </Button>
        </Ripples>
        <Ripples className="react-12-cut color-off-white">
          <Button type="submit" className="btn-cu-lg" color="success">
            {editMode ? t('issuers.form.buttons.edit') : t('issuers.form.buttons.create')}
          </Button>
        </Ripples>
      </div>
    </Form>
  )
}

IssuerForm.propTypes = {
  t: PropTypes.func,
  issuer: PropTypes.object,
  editMode: PropTypes.bool,
  chambersList: PropTypes.array,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleBackToAction: PropTypes.func
}

export default IssuerForm
