/* eslint-disable prefer-const */

export const SPINNER_START = 'SPINNER_START'
export const SPINNER_END = 'SPINNER_END'

const initialState = {
  loading: [],
  message: null,
  fullScreen: undefined
}

const spinnerReducer = (state = initialState, action) => {
  const { payload, type } = action || {}
  const { fullScreen, message } = payload || {}

  switch (type) {
    case SPINNER_END: {
      let loading = [...state.loading]
      loading.pop()

      return {
        ...state,
        loading,
        message: loading.length > 0 ? state.message : initialState.message,
        fullScreen: loading.length > 0 ? state.fullScreen : initialState.fullScreen
      }
    }
    case SPINNER_START: {
      let loading = [...state.loading]
      loading.push(true)

      return {
        ...state,
        loading,
        message: message ? message : initialState.message,
        fullScreen: fullScreen ? fullScreen : initialState.fullScreen
      }
    }
    default:
      return state
  }
}

export const endSpinner = () => {
  return {
    type: SPINNER_END
  }
}

export const startSpinner = (props) => {
  return {
    type: SPINNER_START,
    payload: props
  }
}

export default spinnerReducer
