import PropTypes from 'prop-types'

const HeaderTitle = ({ title }) => {
  return (
    <div className="bread-title">
      <div>
        <h1>{title}</h1>
      </div>
    </div>
  )
}

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default HeaderTitle
