// Modules
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// Components
import Loader from '../Loader'
// Assets
import NotFoundImg from '../../assets/image/notfound.svg'

const UserNotFound = () => {
  const { t, i18n } = useTranslation()
  const [authLoader, setAuthLoader] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    setAuthLoader(false)
  }, [dispatch, i18n.language])

  return (
    <>
      {!authLoader ? (
        <>
          <section className="notfound-section section mid-section new-section">
            <div className="notfound-data text-center">
              <div>
                <img src={NotFoundImg} className="img-fluid notfound" alt="images" />
                <h1 className="fw700">
                  {t('error-msg.not-access1')}
                  {t('error-msg.not-access2')}
                </h1>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section className="notfound-section section mid-section new-section">
          <Loader />
        </section>
      )}
    </>
  )
}

export default UserNotFound
