// ***** start - reducer action name *****
export const reducerActionName = {
  GET_BADGE_DATA: 'GET_BADGE_DATA',
  GET_BADGES_DATA: 'GET_BADGES_DATA',
  SET_TOTAL: 'SET_TOTAL',
  SET_EMPTY_DATA_FLAG: 'SET_EMPTY_DATA_FLAG',
  GET_FILTER_DATA: 'GET_FILTER_DATA',
  SET_SEARCH_DATA: 'SET_SEARCH_DATA',
  SET_AWARD_RECIPIENT: 'SET_AWARD_RECIPIENT',
  SET_AWARD_RECIPIENT_TOTAL: 'SET_AWARD_RECIPIENT_TOTAL',
  DELETE_AWARD_RECIPIENT: 'DELETE_AWARD_RECIPIENT',
  GET_CHAMBER_DATA: 'GET_CHAMBER_DATA',
  GET_FILTER_CHAMBER_DATA: 'GET_FILTER_CHAMBER_DATA',
  GET_RECIPIENT_DETAILS_DATA_BY_ID: 'GET_RECIPIENT_DETAILS_DATA_BY_ID',
  UPDATE_IMAGE_NAME: 'UPDATE_IMAGE_NAME',
  IS_LOADING: 'IS_LOADING',
  SET_BADGE_CREATE: 'SET_BADGE_CREATE',
  SET_BADGE_UPDATE: 'SET_BADGE_UPDATE',
  SET_AWARD_BADGE: 'SET_AWARD_BADGE',
  SET_CSV_FILE_UPLOAD: 'SET_CSV_FILE_UPLOAD',
  SET_CSV_FILE_MESSAGE: 'SET_CSV_FILE_MESSAGE',
  CHANGE_USER_DATA: 'CHANGE_USER_DATA',
  GET_ISSUER_DATA: 'GET_ISSUER_DATA',
  SET_EMPTY_DATA_FLAG_ISSUER: 'SET_EMPTY_DATA_FLAG_ISSUER',
  SET_TOTAL_ISSUER: 'SET_TOTAL_ISSUER',
  SET_ISSUER_BY_ID: 'SET_ISSUER_BY_ID'
}
// ***** end - reducer action name *****

// ***** start - other all constant *****
export const getAllBadgeLimit = 20
export const getAwardBadgeLimit = 10
export const filterFlag = true
export const getAllIssuerLimit = 20
// ***** end - other all constant *****

// ***** start - role constant *****
export const rolesObj = {
  superuser: { key: 'superuser', name: 'SUPERUSER' },
  dihkadmin: { key: 'dihkadmin', name: 'Administration DIHK-Bildungs-gGmbH' },
  dihkemployee: { key: 'dihkemployee', name: 'DIHK-Bildungs-gGmbH Mitarbeiter' },
  ihkadmin: { key: 'ihkadmin', name: 'IHK Projektverantwortliche/-r' },
  ihkagent: { key: 'ihkagent', name: 'IHK Mitarbeiter' },
  cooppartneradmin: { key: 'cooppartneradmin', name: 'Kooperationspartner Admin' },
  cooppartneragent: { key: 'cooppartneragent', name: 'Kooperationspartner Mitarbeiter' }
}
export const notAuthorized = 'Nicht autorisiert'

// ***** end - role constant *****
// ***** start - language constant *****
export const langOptions = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'de',
    label: 'German'
  }
]
// ***** end - language constant *****
// ***** start - professionTitles constant *****
export const professionTitles = [
  {
    value: 'Dr.',
    label: 'Dr.'
  },
  {
    value: 'Prof.',
    label: 'Prof.'
  }
]
// ***** end - professionTitles constant *****
// ***** start - url regular expression *****
export const urlRegex =
  /http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/
// ***** end - url regular expression *****

export const routerPath = {
  CREATE_ISSUER: '/issuer/create',
  EDIT_ISSUER: '/issuer/edit',
  ISSUER_DETAILS: '/badges',
  BADGE_DETAILS: '/badge/details',
  CREATE_BADGE: '/badge/create',
  EDIT_BADGE: '/badge/edit',
  BADGE_RECIPIENT: '/empfaenger',
  RECIPIENT_DETAILS: '/empfaenger/angaben',
  AWARD_BADGE: '/ausstellen',
  USER_NOT_FOUND: '/fehlende-berechtigung',
  ORDER_BADGES: '/order-badges'
}

export const PATH = {
  ISSUERS: 'issuers', // issuers route
  BADGES: 'badges', // badges route
  AWARDS: 'awards', // awards route
  SINGLE_AWARD: 'singleAward' // sinngle award route
}

export const SIDEBAR = {
  DASHBOARD: 'dashboard',
  USER_MANAGEMENT: 'user_management',
  CHAT_MANAGEMENT: 'chat_management',
  OFFER_MANAGEMENT: 'offer_management',
  OFFER_OVERVIEW: 'offer_overview',
  BILLING: 'billing',
  MARKETING: 'marketing',
  OPEN_BADGES: 'badges'
}

export const ACTION = {
  VIEW: 'view',
  VIEW_ACCOUNT_NUMBERS: 'view',
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update',
  SEND_MAIL: 'sendMail',
  SHARE: 'share',
  ORDER_BADGE: 'orderBade',
  BULK_MANAGE: 'bulkManage'
}
