// Modules
import * as CryptoJS from 'crypto-js'
// Helpers
import { config } from '../config'

/**
 * Encrypts data using AES encryption and returns the encrypted string.
 *
 * @param {object} session - The data to be encrypted. It must be an object to be serialized into JSON.
 * @returns {string} The encrypted data as a string.
 */
export const encryptSession = (session) => {
  return CryptoJS.AES.encrypt(JSON.stringify(session), config.adminEncrptKey).toString()
}

/**
 * Decrypts a session token stored in the local storage using AES encryption.
 *
 * @param {string} key - The key used to retrieve the encrypted session token from local storage.
 * @param {boolean} json - If true, the decrypted data is parsed as JSON.
 * @returns {string | object} The decrypted session data. If json is true, returns an object; otherwise, returns a string.
 */
export const decryptSession = (key, json = false) => {
  const token = localStorage.getItem(key)

  if (!token) return

  const decrypted = CryptoJS.AES.decrypt(token, config.adminEncrptKey).toString(CryptoJS.enc.Utf8)
  return json ? JSON.parse(decrypted) : decrypted
}
