// Modules
import React from 'react'
import Router from './router/router'
import { ToastContainer } from 'react-toastify'
import { Spinner } from './components'
// Styles
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  return (
    <>
      <Router />
      <ToastContainer
        icon={true}
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Spinner />
    </>
  )
}

export default App
