export const config = {
  // ***** start - base url *****
  bearerToken: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  loginRedirectUrl: process.env.REACT_APP_API_REDIRECT_TO_LOGIN,
  redirectToAnother: process.env.REACT_APP_REDIRECT_BASE_URL,

  // borderImageSlice:
  orderBadgesPdfLink: process.env.REACT_APP_ORDER_BADGE_PDF_LINK,

  // api url endpoints
  emailEncrptKey: process.env.REACT_APP_API_KEY_OF_ENCRYPTION_FOR_TOKEN,
  adminEncrptKey: process.env.REACT_APP_API_KEY_FOR_ADMIN_DATA_ENCRYPTION,
  redirectBaseUrl: process.env.REACT_APP_REDIRECT_TO_SIDEBAR,

  // ***** start - api url endpoints *****
  apiEndPoints: {
    badgeDetails: process.env.REACT_APP_API_BADGE_DETAILS,
    getAllBadges: process.env.REACT_APP_API_GET_ALL_BADGES,
    deleteBadge: process.env.REACT_APP_API_DELETE_BADGE,
    getAwardBadge: process.env.REACT_APP_API_GET_AWARD_BADGE,
    getChamberData: process.env.REACT_APP_API_GET_CHAMBER,
    revokeBadge: process.env.REACT_APP_API_REVOKE_BADGE,
    uploadImage: process.env.REACT_APP_API_UPLOAD_IMAGE,
    createBadge: process.env.REACT_APP_API_CREATE_BADGE,
    updateBadge: process.env.REACT_APP_API_UPDATE_BADGE,
    issueSingleBadge: process.env.REACT_APP_API_ISSUE_SINGLE_BADGE,
    uploadCsv: process.env.REACT_APP_API_UPLOAD_CSV,
    submitCsv: process.env.REACT_APP_API_SUBMIT_CSV,
    getBadgeImage: process.env.REACT_APP_API_GET_BADGE_IMAGE,
    getSampleCsv: process.env.REACT_APP_API_SAMPLE_CSV,
    getSampleCsvWithExpiryDate: process.env.REACT_APP_API_SAMPLE_CSV_EXPIRY_DATE,
    getAwardImage: process.env.REACT_APP_API_GET_BADGE_AWARED_IMAGE,
    authLogin: process.env.REACT_APP_API_AUTH_LOGIN,
    deleteIssuer: process.env.REACT_APP_API_ISSUER_DELETE,
    createIssuer: process.env.REACT_APP_API_ISSUER_CREATE,
    updateIssuer: process.env.REACT_APP_API_ISSUER_UPDATE,
    getIssuerById: process.env.REACT_APP_API_ISSUER_GET_BY_ID,
    sendEmailToRecepient: process.env.REACT_APP_API_SEND_MAIL_TO_RECEPIENT,

    // redirect end points
    userRedirect: process.env.REACT_APP_REDIRECT_TO_USERS,
    chatRedirect: process.env.REACT_APP_REDIRECT_TO_CHAT,
    mappingRedirect: process.env.REACT_APP_REDIRECT_TO_MAPPING,
    offersRedirect: process.env.REACT_APP_REDIRECT_TO_OFFERS,
    invoicingRedirect: process.env.REACT_APP_REDIRECT_TO_INVOICING,
    marketingmaterialsRedirect: process.env.REACT_APP_REDIRECT_TO_MARKETINGMATERIALS,
    logoutRedirect: process.env.REACT_APP_REDIRECT_TO_LOG_OUT,
    usermanualRedirect: process.env.REACT_APP_REDIRECT_TO_USERMANUAL,
    settingsRedirect: process.env.REACT_APP_REDIRECT_TO_SETTINGS
  }
}
