export const getLocalStorage = (value) => {
  const storedValue = localStorage.getItem(value)
  return storedValue !== null ? JSON.parse(storedValue) : null
}

export const updateLocalStorage = ({ item, value, doDelete = false }) => {
  if (doDelete) {
    localStorage.removeItem(item)
  } else {
    localStorage.setItem(item, JSON.stringify(value))
  }
}
