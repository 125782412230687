// Styles
import '../../assets/css/loading.css'

const PaginationLoad = () => {
  return (
    <div className="text-center my-3 spinner-color">
      <div className="spinner-border" role="status"></div>
    </div>
  )
}

export default PaginationLoad
