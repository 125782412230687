// Modules
import Ripples from 'react-ripples'
import { Alert, Button, Label } from 'reactstrap'
import { FaExclamationCircle } from 'react-icons/fa'
// Components
import { PaginationLoad as Loader } from '../../../../../../../../../components'

const ResponseDetails = ({
  translate,
  files,
  handleDeleteCSVUpload,
  uploadButtonShow,
  handleCSVUpload,
  csvUploadResponse,
  toggleModal,
  isLoading,
  icons
}) => {
  const FileUploadRightPanel = () => {
    const getUploadButton = () => (
      <Ripples className="react-12 color-off-white">
        <Button color="success" className="btn-cu-lg" type="button" onClick={handleCSVUpload}>
          {translate('award-badge-page.validate-csv-file')}
        </Button>
      </Ripples>
    )

    const getCSVUploadDetails = () => (
      <>
        <div className="span-list">
          {translate('award-badge-page.success-data')} : {csvUploadResponse.data.success}
        </div>
        <div className="span-list">
          {translate('award-badge-page.failed-data')} : {csvUploadResponse.data.failed}
        </div>
        <div className="span-list">
          {translate('award-badge-page.total-data')} : {csvUploadResponse.data.total}
        </div>
        <div>
          <Alert color={csvUploadResponse.data.failed === 0 ? 'primary' : 'danger'}>
            <FaExclamationCircle size="14" />
            {translate(
              csvUploadResponse.data.failed === 0
                ? 'award-badge-page.csv-file-success-message'
                : 'award-badge-page.csv-file-error-message'
            )}
          </Alert>
        </div>
        {csvUploadResponse.message.length !== 0 && (
          <Ripples className="react-12 color-off-white">
            <Button color="success" className="btn-cu-lg" type="button" onClick={toggleModal}>
              {translate('award-badge-page.show-csv-data')}
            </Button>
          </Ripples>
        )}
      </>
    )

    return (
      <div className="file-right">
        <Label for="chamber" className="d-block">
          {translate('award-badge-page.right-side.csvsummuryhead')}
        </Label>
        {uploadButtonShow ? (
          getUploadButton()
        ) : csvUploadResponse !== null && csvUploadResponse !== false ? (
          getCSVUploadDetails()
        ) : isLoading ? (
          <Loader />
        ) : null}
      </div>
    )
  }
  return files.map((file, i) => (
    <div key={i} className="file-data-set-main">
      <div className="file-left">
        <div className="file-preview" key={file.path}>
          <img
            src={icons.closeIcon}
            className="img-fluid preview"
            title={translate('award-badge-page.right-side.preview-text')}
            alt="preview"
            onClick={() => handleDeleteCSVUpload(file, i)}
          />
          <div className="one">
            <img src={icons.previewIcon} className="img-fluid" alt="img-plus" />
          </div>
          <div className="two">
            <span className="span-14">{file.path}</span>
          </div>
        </div>
      </div>
      <FileUploadRightPanel />
    </div>
  ))
}

export default ResponseDetails
