// Modules
import { Button, Input, InputGroup } from 'reactstrap'
// Assets
import IconSearch from '../../assets/image/icon-search.svg'

const SearchBox = ({ t, isFocus, handleSearchChange, onFocus }) => (
  <div className="search-box">
    <InputGroup className={isFocus ? 'shadows' : null}>
      <Input
        type="text"
        placeholder={t('badge-card.search-module.search-placeholder-text')}
        onChange={(e) => {
          handleSearchChange(e)
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearchChange(e)
          }
        }}
        onFocus={onFocus}
      />
      <Button color="white">
        <img src={IconSearch} className="img-fluid" alt="icon-search" />
      </Button>
    </InputGroup>
  </div>
)

export default SearchBox
