// Modules
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
// Actions
import { useAuth } from '../../../ducks/Auth.ducks'
import { getBadgeById } from '../../../ducks/Badges.ducks'
// Components
import { HeaderTitle } from '../../../components'
import BadgeDetails from './BadgeDetails'

const BadgeDetailsMain = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const { authState: { auth } = {} } = useAuth()
  const { badge } = useSelector((state) => state.badges)

  useEffect(() => {
    if (!location.state) {
      history.goBack()
    }

    const asyncApiCall = async () => {
      await getBadgeById({ id: location?.state?.id, dispatch })
    }

    asyncApiCall()
  }, [])

  return (
    <>
      <Helmet>
        <title>IHK - Abzeichendetails</title>
      </Helmet>
      <section className="mid-section">
        <div className="container-fluid common-container">
          <HeaderTitle title={t('badge-details-page.head.title')} />
          {badge && <BadgeDetails t={t} auth={auth} badge={badge} />}
        </div>
      </section>
    </>
  )
}

export default BadgeDetailsMain
