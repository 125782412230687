/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/**
 * Creates a FormData object with the values passed in the param.
 *
 * @param {Object} data the data to be transformed in form data
 * @returns a FormData object
 */
export function createFormData(data) {
  let dataObj = new FormData()

  Object.entries(data).forEach(([key, value]) => {
    setValue(key, value, dataObj)
  })

  return dataObj
}

/**
 * Sets the key and value according to their type to the form data object passed.
 *
 * @param {String} key the key to be checked and added to the form data object
 * @param {*} value the value to be checked and added to the form data object
 * @param {Object} dataObj the form data object to set the value
 */
function setValue(key, value, dataObj) {
  dataObj.append(key, value)
}
