const styles = {
  tableTd: {
    minWidth: 'auto'
  },
  detailsButton: {
    border: ' 1px solid rgb(35, 54, 108)',
    borderRadius: '100px',
    fontSize: '9px',
    fontWeight: 'bold',
    lineHeight: '10px',
    padding: '6px 7.5px',
    textTransform: 'uppercase',
    minHeight: 'auto'
  }
}

export default styles
