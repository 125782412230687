// Modules
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Ripples from 'react-ripples'
import Select from 'react-select'
import {
  Button,
  Col,
  Label,
  Row,
  FormGroup,
  Input,
  FormFeedback,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'
import Flatpickr from 'react-flatpickr'
// Helpers
import { professionTitles } from '../../../../../constants/constants'
import { isEmptyObject } from '../../../../../helpers/objects'
// Components
import UploadCSV from './components/UploadCsv'

const AwardFields = ({ t, formik, formRef, badgeId, handleCancelAction }) => {
  const { uploadedCSV } = useSelector((state) => state.awards)
  const [toggleTab, setToggleTab] = useState('2')
  const csvIsUploaded = isEmptyObject(uploadedCSV)

  const handleToggleTab = (tab) => {
    setToggleTab(tab)
    formik.resetForm()
  }

  return (
    <>
      <Nav pills className="mb-5">
        {csvIsUploaded && (
          <NavItem>
            <NavLink active={toggleTab === '1'} onClick={() => handleToggleTab('1')}>
              <span>{t('award-badge-page.left-side.single-issue')}</span>
            </NavLink>
          </NavItem>
        )}

        <NavItem>
          <NavLink active={toggleTab === '2'} onClick={() => handleToggleTab('2')}>
            <span>{t('award-badge-page.right-side.heading')}</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={toggleTab}>
        <TabPane tabId="1">
          <Row>
            <Col lg={12}>
              <div className="award-text-data" ref={formRef}>
                <div className="title">
                  <h3>{t('award-badge-page.left-side.heading')}</h3>
                </div>
                <div className="form-group floating-label-group">
                  <Select
                    id="professionTitle"
                    name="professionTitle"
                    options={professionTitles}
                    isClearable={true}
                    className="react-select with-search"
                    classNamePrefix="select"
                    placeholder={t('award-badge-page.left-side.title')}
                    onChange={(data) =>
                      formik.setFieldValue('professionTitle', (data && data.value) || null)
                    }
                  />
                </div>
                <FormGroup>
                  <Input
                    id="name"
                    name="name"
                    placeholder={t('award-badge-page.left-side.name')}
                    type="text"
                    {...(formik.errors.name && formik.touched.name ? { invalid: true } : {})}
                    onChange={(event) => formik.setFieldValue('name', event.target.value)}
                    value={formik.values.name}
                  />
                  <FormFeedback>{formik.errors.name}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    id="surname"
                    name="surname"
                    placeholder={t('award-badge-page.left-side.surname')}
                    type="text"
                    {...(formik.errors.surname && formik.touched.surname ? { invalid: true } : {})}
                    onChange={(event) => formik.setFieldValue('surname', event.target.value)}
                    value={formik.values.surname}
                  />
                  <FormFeedback>{formik.errors.surname}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    id="email"
                    name="email"
                    placeholder={t('award-badge-page.left-side.email')}
                    type="email"
                    {...(formik.errors.email && formik.touched.email ? { invalid: true } : {})}
                    onChange={(event) => formik.setFieldValue('email', event.target.value)}
                    value={formik.values.email}
                  />
                  <FormFeedback>{formik.errors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Flatpickr
                    id="startDate"
                    name="startDate"
                    className={`form-control flatpickr-input ${
                      formik.errors.startDate && formik.touched.startDate && 'is-invalid'
                    }`}
                    onChange={(date) => {
                      formik.setFieldValue('startDate', date[0])
                    }}
                    options={{
                      dateFormat: 'd.m.Y',
                      maxDate: 'today'
                    }}
                    placeholder={t('award-badge-page.left-side.issueddateplaceholder')}
                    value={formik.values.startDate}
                  />
                  <FormFeedback>{formik.errors.startDate}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Flatpickr
                    id="endDate"
                    name="endDate"
                    className={`form-control flatpickr-input`}
                    onChange={(date) => {
                      formik.setFieldValue('endDate', date[0])
                    }}
                    options={{
                      dateFormat: 'd.m.Y',
                      minDate: formik.values.startDate
                    }}
                    placeholder={t('award-badge-page.left-side.expdateplaceholder')}
                    value={formik.values.endDate}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Col lg={12}>
            <div className="award-csv-data">
              <div className="form-group">
                <Label for="chamber">{t('award-badge-page.right-side.csvhead')}</Label>
                <UploadCSV t={t} badgeId={badgeId} />
              </div>
            </div>
          </Col>
        </TabPane>
      </TabContent>

      <div className="button-tab-bottom">
        <Ripples className="react-12-cut color-off-white">
          <Button color="white" className="btn-cu-lg" onClick={handleCancelAction}>
            {t('award-badge-page.right-side.cancel-btn')}
          </Button>
        </Ripples>

        <Ripples className="react-12-cut color-off-white">
          <Button
            color="success"
            className="btn-cu-lg"
            type="submit"
            disabled={toggleTab !== '1' && csvIsUploaded}>
            {t('award-badge-page.right-side.save-btn')}
          </Button>
        </Ripples>
      </div>
    </>
  )
}

export default AwardFields
