// Modules
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const EmptyData = ({ textId }) => {
  const { t } = useTranslation()

  return (
    <div className="data-not-found">
      <h2>{t(textId)}</h2>
    </div>
  )
}

EmptyData.propTypes = {
  textId: PropTypes.string.isRequired
}

export default EmptyData
