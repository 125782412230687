// Modules
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { CardBody } from 'reactstrap'
// Helpers
import { convertDateFormat } from '../../helpers/dates'
import { getBadgeImageUrl } from '../../view/Badges/UpsertBadge/helpers/urlFormatter'

const Card = ({ badge }) => {
  const { t } = useTranslation()
  const { name, createdAt, image, description, awardsCount, issuer } = badge || {}

  return (
    <CardBody>
      <div className="date-div">
        <div className="date">
          <span>{convertDateFormat(createdAt)}</span>
        </div>
        <div className="name">
          <ReactTooltip place="top" type="dark" />
          <p data-tip={issuer?.name} className="chamber">
            {issuer?.name}
          </p>
        </div>
      </div>
      <div className="card-img">
        <img
          src={getBadgeImageUrl(image)}
          className="img-fluid"
          alt={t('badge-card.cards.cards-badge-image-alt')}
        />
      </div>
      <div className="card-text-data">
        <h4>{name}</h4>
        <p>{parse(description)}</p>
        <span className="mix">
          <h3>
            {awardsCount}
            &nbsp;
            {awardsCount > 1
              ? awardsCount <= 0
                ? t('badge-card.cards.award-text')
                : t('badge-card.cards.awards-text')
              : t('badge-card.cards.award-text')}
          </h3>
        </span>
      </div>
    </CardBody>
  )
}

Card.propTypes = {
  badge: PropTypes.object.isRequired
}

export default Card
