import colors from '../../helpers/colors'

const styles = {
  copyIcon: {
    fontSize: '20px',
    color: colors.darkSlateBlue
  },
  closeIcon: {
    fontSize: '20px',
    color: colors.darkSlateBlue
  }
}

export default styles