// Modules
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
// Components
import { Card } from '../../../../../components'
// Helpers
import { routerPath } from '../../../../../constants/constants'

const CardListing = ({ badges }) => {
  // Sort the badgesData alphabetically by name
  // Memoize the sorted data to avoid unnecessary sorting on each render
  const sortedBadges = useMemo(() => {
    return badges.slice().sort((a, b) => a.name.localeCompare(b.name))
  }, [badges])

  return (
    <>
      {sortedBadges.length !== 0 &&
        sortedBadges.map((badge, i) => (
          <Link
            key={i}
            to={{
              pathname: routerPath.BADGE_DETAILS,
              state: {
                id: badge.id,
                issuerId: badge.issuer.id,
                issuerName: badge.issuer.name
              }
            }}
            className="card">
            <Card badge={badge} />
          </Link>
        ))}
    </>
  )
}

CardListing.propTypes = {
  badges: PropTypes.array.isRequired
}

export default CardListing
