/**
 * Checks if the provided object is empty. An object is considered empty if it has no own enumerable properties
 * and is a plain object (not an instance of a subclass or other constructors like Array or Date).
 * @param {Object} obj - The object to check for emptiness.
 * @returns {boolean} - Returns true if the object is empty, false otherwise.
 */

export function isEmptyObject(obj) {
  if (obj === null || typeof obj !== 'object') {
    return false
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object
}
