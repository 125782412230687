// Modules
import classNames from 'classnames'
import PropTypes from 'prop-types'
// Styles
import style from './Checkbox.css'

const Checkbox = ({ checked, indeterminate, onClick }) => {
  const handleOnChange = (e) => {
    onClick && onClick(e)
  }
  return (
    <label>
      <input style={style.input} checked={checked} type="checkbox" onChange={handleOnChange} />
      <span
        className={classNames('checkbox', {
          'icon-less': indeterminate,
          'icon-check': checked
        })}
        style={{
          ...(checked || indeterminate ? style.checked : {})
        }}
      />
    </label>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  classes: PropTypes.object,
  input: PropTypes.object,
  indeterminate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  meta: PropTypes.object,
  onClick: PropTypes.func,
  style: PropTypes.shape({
    checkbox: PropTypes.object,
    container: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.object
  })
}

export default Checkbox
