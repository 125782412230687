import colors from '../../helpers/colors'

const styles = {
  warningIcon: {
    fontSize: '30px',
    color: colors.toastedYellow
  },
  closeIcon: {
    fontSize: '20px',
    color: colors.darkSlateBlue
  }
}

export default styles
