// Api
import api from '../helpers/api'

export const SAVE_CHAMBER_DATA = 'SAVE_CHAMBER_DATA'
export const SAVE_ISSUER_DATA = 'SAVE_ISSUER_DATA'
export const SAVE_FILTER_ISSUER_DATA = 'SAVE_FILTER_ISSUER_DATA'
export const SET_EMPTY_DATA_FLAG_ISSUER = 'SET_EMPTY_DATA_FLAG_ISSUER'
export const SET_LOADING = 'SET_LOADING'
export const CLEAR_ISSUER_DATA = 'CLEAR_ISSUER_DATA'
export const CLEAR_FILTER_ISSUER_DATA = 'CLEAR_FILTER_ISSUER_DATA'

const defaultState = {
  chambers: [],
  issuersList: [],
  searchFilters: undefined,
  isDataEmpty: false,
  loading: true
}

// Reducer
export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_ISSUER_DATA: {
      return Object.assign({}, state, {
        issuersList: defaultState.issuersList
      })
    }
    case CLEAR_FILTER_ISSUER_DATA: {
      return Object.assign({}, state, {
        searchFilter: defaultState.searchFilter
      })
    }
    case SAVE_CHAMBER_DATA: {
      return Object.assign({}, state, {
        ...state,
        chambers: action.payload
      })
    }
    case SAVE_ISSUER_DATA: {
      return Object.assign({}, state, {
        ...state,
        issuersList: action.payload
      })
    }

    case SAVE_FILTER_ISSUER_DATA: {
      return Object.assign({}, state, {
        ...state,
        searchFilter: action.payload
      })
    }
    case SET_EMPTY_DATA_FLAG_ISSUER: {
      return Object.assign({}, state, {
        ...state,
        isDataEmpty: action.payload
      })
    }
    case SET_LOADING: {
      return Object.assign({}, state, {
        ...state,
        loading: action.payload
      })
    }
    default:
      return state
  }
}

export const clearIssuers = () => {
  return {
    type: CLEAR_ISSUER_DATA
  }
}

export const clearSearchFilter = () => {
  return {
    type: CLEAR_FILTER_ISSUER_DATA
  }
}

export const saveChambers = (chambers) => {
  return {
    type: SAVE_CHAMBER_DATA,
    payload: chambers
  }
}

export const saveIssuers = (issuersList) => {
  return {
    type: SAVE_ISSUER_DATA,
    payload: issuersList
  }
}

export const saveSearchFilters = (searchFilter) => {
  return {
    type: SAVE_FILTER_ISSUER_DATA,
    payload: searchFilter
  }
}

export const saveDataEmpty = (isDataEmpty) => {
  return {
    type: SET_EMPTY_DATA_FLAG_ISSUER,
    payload: isDataEmpty
  }
}

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading
  }
}

export const getChambers = () => async (dispatch) => {
  return await api
    .get('chamber/get')
    .then((response) => {
      const { data, status } = response.data
      dispatch(saveChambers(data))
      if (status === 200) {
        dispatch(saveDataEmpty(true))
      }
    })
    .catch((e) => {
      console.log('Error calling getChambers: ', e)
    })
}

export const getIssuers = () => async (dispatch) => {
  return await api
    .get('issuer/get')
    .then((response) => {
      const { data, status } = response.data

      dispatch(saveIssuers(data))
      if (status === 200) {
        dispatch(saveDataEmpty(true))
        dispatch(setLoading(false))
      }
    })
    .catch((e) => {
      console.log('Error calling getIssuers: ', e)
    })
}

export const getIssuerById = async ({ id, errorCb, successCb }) => {
  return await api
    .get(`issuer/get/${id}`)
    .then((response) => {
      successCb && successCb(response.data)
    })
    .catch((e) => {
      console.log('Error calling getIssuers: ', e)
      errorCb && errorCb()
    })
}

export const createIssuer = async ({ data, errorCb, successCb }) => {
  return await api
    .post('issuer/create', data)
    .then(() => {
      successCb && successCb()
    })
    .catch((e) => {
      console.log('Error calling createIssuer: ', e)
      errorCb && errorCb()
    })
}

export const updateIssuer = async ({ id, data, errorCb, successCb }) => {
  return await api
    .post(`issuer/update/${id}`, data)
    .then(() => {
      successCb && successCb()
    })
    .catch((e) => {
      console.log('Error calling updateIssuer: ', e)
      errorCb && errorCb()
    })
}

export const deleteIssuer = async ({ id, errorCb, successCb }) => {
  return await api
    .post(`issuer/delete/${id}`)
    .then(() => {
      successCb && successCb()
    })
    .catch((error) => {
      console.log('Error calling deleteIssuer: ', error)
      errorCb && errorCb(error.response.status)
    })
}
