// Modules
import React, { useState, useEffect, Suspense } from 'react'
import { Switch, Route, Router as Routers } from 'react-router-dom'
// Action
import { useAuth } from '../ducks/Auth.ducks'
// Utils
import routes from './routes'
import { history } from '../history'
// Components
import { LayoutHeader, LayoutSidebar, Loader } from '../components'

const Router = () => {
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { authState: { auth, loading } = {} } = useAuth()

  useEffect(() => {
    setIsLoading(loading)
  }, [auth])

  const active = (active) => {
    setIsActive(active)
  }

  const menu = routes.map((route) => {
    return route.component ? (
      <Route key={route.name} path={route.path} exact name={route.name}>
        <route.component />
      </Route>
    ) : null
  })

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <LayoutHeader />
          <LayoutSidebar auth={auth} active={active} />
          <div className={`App with-box ${isActive ? 'side-visible' : ''}`}>
            <Routers history={history}>
              <Suspense fallback={<Loader />}>
                <Switch>{menu}</Switch>
              </Suspense>
            </Routers>
          </div>
        </>
      )}
    </>
  )
}

export default Router
