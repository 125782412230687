// Modules
import parse from 'html-react-parser'
import { Card, CardBody } from 'reactstrap'
// Helpers
import { config } from '../../../../../config'
import { convertDateFormat } from '../../../../../helpers/dates'

const Fields = ({ t, awardDetails }) => {
  const {
    firstName,
    lastName,
    email,
    image,
    professionTitle,
    issueDate,
    expiryDate,
    meta,
    issuerDetails = {},
    badgeDetails = {}
  } = awardDetails

  const awardImageLink = `${config.apiBaseUrl}${config.apiEndPoints.getAwardImage}?file=${image}`

  return (
    <div className="badges-details-section detail-badge-all">
      <div className="title">
        <h3 className="mb23">{t('recipient-details-page.one-side.heading')}</h3>
      </div>
      <div className="alls">
        <div>
          <Card>
            <CardBody>
              <span className="span-10">{t('recipient-details-page.one-side.card1.head')}</span>
              <p className="scroll">{badgeDetails.name}</p>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <span className="span-10">{t('recipient-details-page.one-side.card2.head')}</span>
              <p className="scroll">
                {professionTitle ? professionTitle : ''} {firstName} {lastName}
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <span className="span-10">{t('recipient-details-page.one-side.card3.head')}</span>
              <div className="img-data">
                <a href={awardImageLink} target="_blank" rel="noreferrer" className="text">
                  <img src={awardImageLink} className="img-fluid" alt={badgeDetails.name} />
                </a>
              </div>
            </CardBody>
          </Card>
        </div>
        <div>
          <Card>
            <CardBody>
              <span className="span-10">{t('recipient-details-page.one-side.issuer')}</span>
              <p className="scroll">{issuerDetails.name}</p>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <span className="span-10">{t('recipient-details-page.one-side.card4.head')}</span>
              <p className="scroll">{parse(badgeDetails.description)}</p>
            </CardBody>
          </Card>
        </div>
        <div>
          <Card>
            <CardBody>
              <span className="span-10">{t('recipient-details-page.one-side.card6.head')}</span>
              <p className="scroll">{convertDateFormat(issueDate, false, true)}</p>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <span className="span-10">{t('recipient-details-page.one-side.card7.head')}</span>

              <p className="scroll">
                {expiryDate !== null && expiryDate !== 0
                  ? convertDateFormat(expiryDate, false, true)
                  : t('recipient-list-page.table-data.no-expiry')}
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <span className="span-10">{t('recipient-details-page.one-side.card8.head')}</span>
              <a href={`mailto:${email}`} target="_blank" rel="noreferrer" className="text">
                {email}
              </a>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <span className="span-10">{t('recipient-details-page.one-side.card5.head')}</span>
              <a href={`${meta?.id}`} target="_blank" rel="noreferrer" className="text">
                {meta?.id}
              </a>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Fields
