// Modules
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
// Actions
import { getBadgeAwardRecipients } from '../../../ducks/Awards.ducks'
// Components
import { HeaderTitle } from '../../../components'
import AwardsForm from './AwardsForm'

const AwardsFormMain = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const badgeId = location.state.id

  const { awardRecipients } = useSelector((state) => state.awards)

  useEffect(() => {
    if (!location.state) {
      history.goBack()
    }

    const asyncApiCall = async () => {
      await getBadgeAwardRecipients({ id: badgeId, dispatch })
    }

    asyncApiCall()
  }, [getBadgeAwardRecipients])

  return (
    <>
      <Helmet>
        <title>IHK - Badge Vergabe</title>
      </Helmet>
      <section className="mid-section">
        <div className="container-fluid common-container">
          <HeaderTitle title={t('award-badge-page.head.title')} />
          <AwardsForm t={t} badgeId={badgeId} awardRecipients={awardRecipients} />
        </div>
      </section>
    </>
  )
}

export default AwardsFormMain
