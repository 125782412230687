// Modules
import { Container } from 'reactstrap'
// Assets
import loaderImg from '../../assets/image/loading-spinner.svg'

const Loader = () => {
  return (
    <section className="loader">
      <Container fluid>
        <div className="loader-data">
          <div className="spinner">
            <img src={loaderImg} className="img-fluid" alt="images" />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Loader
