// Modules
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
// ReactStrap
import { Button } from 'reactstrap'
// Styles
import styles from './BulkBadgeTransfer.css'
import { Popup } from '../../../../../components'
import { isEmptyObject } from '../../../../../helpers/objects'

function BulkBadgeTransfer({ t, display, bulkSelection, issuers, handleBulkTransfer }) {
  const [modal, showModal] = useState(false)
  const [selectedIssuer, setSelectedIssuer] = useState({})

  useEffect(() => {}, [display])

  const handleBulkBadgesTransfer = () => {
    handleBulkTransfer && handleBulkTransfer({ issuerId: selectedIssuer.id, successCb: () => {} })
  }

  return (
    <div id="bulk-action" style={{ ...styles.container, ...(display ? {} : styles.hide) }}>
      <div style={styles.innerContainer}>
        <h3 style={styles.title}>{t('badges.listing.transfer-view.title')}</h3>
        <Select
          id="name"
          name="name"
          options={issuers}
          isClearable={true}
          isSearchable={true}
          className="react-select with-search"
          classNamePrefix="select"
          placeholder={t('badges.listing.table.issuer')}
          menuPlacement="top"
          onChange={(value) => setSelectedIssuer(value || {})}
        />
        <Button
          color="primary"
          className="btn-primary btn-20 float-right"
          style={styles.button}
          onClick={() => showModal(true)}
          disabled={isEmptyObject(selectedIssuer)}>
          {t('badges.listing.buttons.transfer')}
        </Button>
      </div>
      <Popup
        show={modal}
        onClick={handleBulkBadgesTransfer}
        changeSetShowToFalse={() => showModal(false)}
        headingText={t('badges.listing.transfer-view.popup.title')}
        paragraphText={t('badges.listing.transfer-view.popup.note', {
          selectedCount: bulkSelection && bulkSelection.length,
          newIssuer: selectedIssuer.label
        })}
        buttonIsDisabled={true}
        buttonText={t('badges.listing.buttons.transfer')}
      />
    </div>
  )
}

BulkBadgeTransfer.propTypes = {
  t: PropTypes.func,
  display: PropTypes.bool,
  issuers: PropTypes.array,
  bulkSelection: PropTypes.array,
  handleScrollToTop: PropTypes.func
}

export default BulkBadgeTransfer
