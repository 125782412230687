// Modules
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Actions
import { getBadgeById } from '../../../ducks/Badges.ducks'
// Components
import { HeaderTitle, Loader } from '../../../components'
import UpsertBadge from './UpsertBadge'

const UpsertBadgeMain = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const { loading, badge } = useSelector((state) => state.badges)

  const { id: badgeId } = location.state || {}
  const editMode = !!badgeId

  useEffect(() => {
    if (!location.state) {
      history.goBack()
    }

    const asyncApiCall = async () => {
      editMode && (await getBadgeById({ id: location.state?.id, dispatch }))
    }

    asyncApiCall()
  }, [])

  return (
    <>
      <Helmet>
        <title>IHK - Abzeichen erstellen/bearbeiten</title>
      </Helmet>
      <section className="mid-section">
        <div className="container-fluid common-container">
          <HeaderTitle title={t('create-badge-page.head.title')} />

          {editMode ? (
            badge && <UpsertBadge badge={badge} editMode={editMode} />
          ) : (
            <UpsertBadge editMode={editMode} />
          )}
          {loading && <Loader />}
        </div>
      </section>
    </>
  )
}

export default UpsertBadgeMain
