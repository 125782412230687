// Modules
import * as CryptoJS from 'crypto-js'
// Helpers
import { config } from '../config'

/**
 * Decrypts an encrypted email using AES encryption with Pkcs7 padding.
 * @param {object} email - The recipient object containing the encrypted email.
 * @returns {string} - The decrypted email or an empty string if decryption fails.
 */
export function encryptEmail({ email }) {
  if (!email) {
    return
  }
  const encryptedEmail = CryptoJS.AES.encrypt(JSON.stringify(email), config.emailEncrptKey, {
    padding: CryptoJS.pad.Pkcs7
  }).toString()

  return encryptedEmail
}
