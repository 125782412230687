// ***** Start - import from package *****
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// ***** end - import from package *****

// ***** Start - import from files *****
import en from './translator/en.json'
import de from './translator/de.json'
// ***** end - import from files *****

// ***** start - resources for translation file *****
const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  }
}
// ***** end - resources for translation file *****

// ***** start - config. of translation file *****
i18n.use(initReactI18next).init({
  resources,
  lng: 'de',
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false
  }
})
// ***** end - config. of translation file *****

export default i18n
