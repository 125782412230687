// Modules
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
// Assets
import Iconback from '../../../../../assets/image/icon-left.svg'

const Breadcrumb = ({ t, authToken, issuer, editMode, routerPath }) => {
  return (
    <div className="arrow-data">
      <div className="left-data">
        <div className="head-back-data">
          <div className="back-data">
            {!editMode && authToken !== null ? (
              <Link to={`/?token=${authToken}`}>
                <img src={Iconback} className="img-fluid" alt="icon-back" />
              </Link>
            ) : (
              <Link
                to={{
                  pathname: routerPath.ISSUER_DETAILS,
                  state: {
                    id: issuer?.id,
                    name: issuer?.name
                  }
                }}>
                <img src={Iconback} className="img-fluid" alt="icon-back" />
              </Link>
            )}
          </div>
          {editMode ? (
            <div className="head-data">
              <h3>{t('issuers.general.back-edit')}</h3>
            </div>
          ) : (
            <div className="head-data">
              <h3>{t('issuers.general.back-create')}</h3>
            </div>
          )}
        </div>
        {editMode ? (
          <p className="span-10 mb-0 text-uppercase">
            {t('issuers.general.back-description-edit')}
          </p>
        ) : (
          <p className="span-10 mb-0 text-uppercase">
            {t('issuers.general.back-description-create')}
          </p>
        )}
      </div>
    </div>
  )
}

Breadcrumb.propTypes = {
  t: PropTypes.func,
  authToken: PropTypes.string,
  issuer: PropTypes.object,
  editMode: PropTypes.bool,
  routerPath: PropTypes.object
}

export default Breadcrumb
