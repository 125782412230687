// Modules
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
// Components
import IssuerCard from './components/IssuerCard'
// Helpers
import { routerPath } from '../../../../../constants/constants'

const IssuerList = ({ listIssuers }) => {
  return (
    <>
      {listIssuers &&
        listIssuers.length !== 0 &&
        listIssuers.map((issuer, i) => (
          <Link
            key={i}
            to={{
              pathname: routerPath.ISSUER_DETAILS,
              state: {
                id: issuer.id,
                name: issuer.name,
                issuer
              }
            }}
            className="card">
            <IssuerCard issuer={issuer} />
          </Link>
        ))}
    </>
  )
}

IssuerList.propTypes = {
  listIssuers: PropTypes.array.isRequired
}

export default IssuerList
