// Modules
import { Form, Field } from 'formik'
import { TabPane, TabContent, Row, Col, Label, Button } from 'reactstrap'
import Select from 'react-select'
import Ripples from 'react-ripples'
import PropTypes from 'prop-types'
// Helpers
import { getBadgeImageUrl } from '../../helpers/urlFormatter'
// Components
import ImageDropbox from '../ImageDropbox'
// Assets
import IconClose from '../../../../../assets/image/icon-delete.svg'

const BadgeForm = ({
  badge,
  editMode,
  imageDelete,
  translate,
  activeTab,
  issuerData,
  values,
  errors,
  touched,
  handleTabToggle,
  setFieldValue,
  handleBackToAction,
  handleBadgeImageDelete
}) => {
  return (
    <Form>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col lg={6}>
              <div className="tab-field-data">
                <div
                  className={`form-group floating-label-group ${
                    errors.issuer && touched.issuer && 'error-gives'
                  }`}>
                  <Select
                    value={values.issuer}
                    options={issuerData}
                    isDisabled={true}
                    isClearable={false}
                    isSearchable={true}
                    id="issuer"
                    name="issuer"
                    className="react-select with-search"
                    classNamePrefix="select"
                    placeholder={translate('create-badge-page.tab1.chamber-placeholder')}
                  />
                  {errors.issuer && touched.issuer ? (
                    <div className="error-msg">{errors.issuer}</div>
                  ) : null}
                </div>

                <div
                  className={`form-group floating-label-group ${
                    errors.name && touched.name && 'error-gives'
                  }`}>
                  <Field
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    autoComplete="off"
                    placeholder={translate('create-badge-page.tab1.name-label')}
                  />
                  <label className="floating-label">
                    {translate('create-badge-page.tab1.name-label')}
                  </label>
                  {errors.name && touched.name ? (
                    <div className="error-msg">{errors.name}</div>
                  ) : null}
                </div>
                <div
                  className={`form-group floating-label-group mb23 ${
                    errors.description && touched.description && 'error-gives'
                  }`}>
                  <Field
                    as="textarea"
                    name="description"
                    id="description"
                    className="form-control"
                    rows="5"
                    autoComplete="off"
                    placeholder={translate('create-badge-page.tab1.description-label')}
                  />
                  <label className="floating-label">
                    {translate('create-badge-page.tab1.description-label')}
                  </label>
                  {errors.description && touched.description ? (
                    <div className="error-msg">{errors.description}</div>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-field-data">
                <p className="fw700 mb-0">{translate('create-badge-page.tab1.card1.head')}</p>
                <p>{translate('create-badge-page.tab1.card1.text')}</p>
              </div>
              <div className="form-group">
                <Label for="chamber">{translate('create-badge-page.tab1.image-label')}</Label>
                {editMode ? (
                  imageDelete ? (
                    <ImageDropbox setFieldValue={setFieldValue} />
                  ) : (
                    <div className="dropzone-img-view">
                      <div className="thumb">
                        <Button
                          color="danger"
                          type="button"
                          onClick={() => {
                            setFieldValue('image', [])
                            handleBadgeImageDelete()
                          }}
                          className="btn-delete">
                          <img src={IconClose} className="img-fluid" />
                        </Button>
                      </div>
                      <div className="dropzone-inner">
                        <img
                          className="img-fluid"
                          src={getBadgeImageUrl(badge?.image)}
                          alt={badge?.name}
                        />
                      </div>
                    </div>
                  )
                ) : (
                  <ImageDropbox setFieldValue={setFieldValue} />
                )}

                {errors.image && touched.image ? (
                  <div className="error-msg">{errors.image}</div>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="button-tab-bottom">
            <Ripples className="react-12 color-off-white">
              <Button color="white" className="btn-cu-lg" onClick={handleBackToAction}>
                {translate('create-badge-page.tab1.cancel-btn')}
              </Button>
            </Ripples>

            <Ripples
              className="react-12-cut color-off-white"
              onClick={() => handleTabToggle('2', values)}>
              <Button color="success" type="button" className="btn-cu-lg">
                {translate('create-badge-page.tab1.next-btn')}
              </Button>
            </Ripples>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col lg={6}>
              <div className="tab-field-data">
                <div
                  className={`form-group floating-label-group ${
                    errors.criteriaURL && touched.criteriaURL && 'error-gives'
                  }`}>
                  <Field
                    type="text"
                    className="form-control"
                    name="criteriaURL"
                    id="criteriaURL"
                    autoComplete="off"
                    placeholder={translate('create-badge-page.tab2.url-label')}
                  />
                  <label className="floating-label">
                    {translate('create-badge-page.tab2.url-label')}
                  </label>
                  {errors.criteriaURL && touched.criteriaURL ? (
                    <div className="error-msg">{errors.criteriaURL}</div>
                  ) : null}
                </div>

                <div
                  className={`form-group floating-label-group mb23 ${
                    errors.criteriaDescription && touched.criteriaDescription && 'error-gives'
                  }`}>
                  <Field
                    as="textarea"
                    name="criteriaDescription"
                    id="criteriaDescription"
                    className="form-control"
                    rows="5"
                    autoComplete="off"
                    placeholder={translate('create-badge-page.tab2.description-placeholder')}
                  />
                  <label className="floating-label">
                    {translate('create-badge-page.tab2.description-label')}
                  </label>
                  {errors.criteriaDescription && touched.criteriaDescription ? (
                    <div className="error-msg">{errors.criteriaDescription}</div>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-field-data tab2">
                <p className="fw700 mb-0">{translate('create-badge-page.tab2.card1.head')}</p>
                <p>{translate('create-badge-page.tab2.card1.text')}</p>
              </div>
            </Col>
          </Row>
          <div className="button-tab-bottom">
            <Ripples className="react-12 color-off-white" onClick={() => handleTabToggle('1')}>
              <Button color="white" type="button" className="btn-cu-lg">
                {translate('create-badge-page.tab2.back-btn')}
              </Button>
            </Ripples>
            <Ripples
              className={`react-12-cut color-off-white 
              }`}>
              <Button color="success" type="submit" className="btn-cu-lg">
                {editMode
                  ? translate('badges.form.buttons.edit')
                  : translate('badges.form.buttons.create')}
              </Button>
            </Ripples>
          </div>
        </TabPane>
      </TabContent>
    </Form>
  )
}

BadgeForm.propTypes = {
  badge: PropTypes.object,
  imageDelete: PropTypes.bool,
  editMode: PropTypes.bool,
  translate: PropTypes.func,
  activeTab: PropTypes.string,
  issuerData: PropTypes.array,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleTabToggle: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBackToAction: PropTypes.func,
  handleBadgeImageDelete: PropTypes.func
}

export default BadgeForm
