// Configs
import { config } from '../../../../config'
/**
 * Constructs and returns the full URL for a badge image using the provided image name.
 *
 * @param {string} name - The name of the badge image.
 * @returns {string|null} The full URL for the badge image, or null if imageName is falsy.
 */
export function getBadgeImageUrl(name) {
  if (name) {
    return `${config.apiBaseUrl}${config.apiEndPoints.getBadgeImage}?file=${name}`
  }
  return null
}
