// Modules
import InfiniteScroll from 'react-infinite-scroll-component'
import PropTypes from 'prop-types'

const PaginationInfiniteScroll = ({
  dataLength,
  loadMore,
  hasMore,
  scrollableTargetId,
  children,
  classNameScroll
}) => {
  return (
    <InfiniteScroll
      className={classNameScroll ? classNameScroll : ''}
      dataLength={dataLength}
      next={loadMore}
      scrollableTarget={scrollableTargetId}
      hasMore={hasMore}>
      {children}
    </InfiniteScroll>
  )
}

PaginationInfiniteScroll.propTypes = {
  dataLength: PropTypes.number.isRequired,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool.isRequired,
  scrollableTargetId: PropTypes.string.isRequired,
  children: PropTypes.object,
  classNameScroll: PropTypes.string
}

export default PaginationInfiniteScroll
