// Modules
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Spinner = ({ fullScreen }) => {
  const [open, setOpen] = useState(false)
  const spinnerInitial = useSelector((state) => state.spinner)

  useEffect(() => {
    setOpen(spinnerInitial.loading.length > 0)
  }, [spinnerInitial.loading])

  if (!open || fullScreen !== spinnerInitial.fullScreen) {
    return null
  }

  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: 'rgba(32, 51, 78, 0.4)',
        borderRadius: '12px 12px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
        inset: 0,
        justifyContent: 'center',
        position: fullScreen && spinnerInitial.fullScreen ? 'fixed' : 'absolute',
        zIndex: 1400
      }}>
      <div className="text-center my-3 spinner-color">
        <div className="spinner-border" style={{ width: '4rem', height: '4rem' }} role="status" />
      </div>
      {spinnerInitial.message && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px 0px'
          }}>
          <p>{spinnerInitial.message}</p>
        </div>
      )}
    </div>
  )
}

export default Spinner
