const Dropzone = ({ translate, getRootProps, getInputProps, icons, config }) => {
  return (
    <>
      <div
        {...getRootProps({
          className: 'dropzone'
        })}>
        <input {...getInputProps()} />
        <div className="dropzone-inner">
          <img src={icons.add} className="img-fluid" alt="img-plus" />
          <p>
            {translate('award-badge-page.right-side.csvplaceholder1')}
            <br />
            {translate('award-badge-page.right-side.csvplaceholder2')}
          </p>
        </div>
      </div>
      <div className="sample-file">
        <a
          href={`${config.apiBaseUrl}${config.apiEndPoints.getSampleCsv}`}
          className="download"
          download>
          <img src={icons.download} className="img-fluid" alt="img-plus" />
          <span className="span-14">
            {translate('award-badge-page.right-side.defaultSvgFileTitle')}
          </span>
        </a>
        <a
          href={`${config.apiBaseUrl}${config.apiEndPoints.getSampleCsvWithExpiryDate}`}
          className="download"
          download>
          <img src={icons.download} className="img-fluid" alt="img-plus" />
          <span className="span-14">
            {translate('award-badge-page.right-side.svgFileWithExpiryDateTitle')}
          </span>
        </a>
      </div>
    </>
  )
}

export default Dropzone
